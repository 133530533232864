import { render, staticRenderFns } from "./dresserInfo.vue?vue&type=template&id=755df61c&scoped=true&"
import script from "./dresserInfo.vue?vue&type=script&lang=js&"
export * from "./dresserInfo.vue?vue&type=script&lang=js&"
import style0 from "./dresserInfo.vue?vue&type=style&index=0&id=755df61c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755df61c",
  null
  
)

export default component.exports