<template>
    <!-- 我的资源-摄影师-信息详情部分 -->
    <div class="lensman_info_box">
        <!-- 面包屑导航 -->
        <!-- <bread-crumb :breadcrumb="breadcrumb"></bread-crumb>
        <hr> -->
        <!-- 标签页部分 -->
        <el-tabs 
            type="border-card"
            v-model="infoTabs"
            @tab-click="handleClick">
            <el-tab-pane 
                label="我的资料" 
                name="1">
                <center-lensman-info-data
                    ref="infoForm"
                    :handle-avatar="handleDresserAvatar"
                    :handle-info="handleModifyDresser">
                </center-lensman-info-data>
            </el-tab-pane>
            <el-tab-pane 
                label="网红化妆师" 
                name="2"
                lazy>
                <center-member-tag
                    edit-router="centerDresserCelebrity"
                    :list="work_list"
                    @handleDelete="handleDel">
                    <!-- 已上传提示 -->
                    <template #tipsMore>
                        <div class="member-tag-tips-more">
                            网红化妆师你好，了解更多
                            <el-link type="primary">
                                网红化妆师特权
                            </el-link>
                        </div>
                    </template>
                    <!-- 网红未上传 -->
                    <template #notingTitle>
                        <div>
                            网红化妆师你好，了解更多
                            <el-link type="primary">网红化妆师特权</el-link>
                        </div>
                    </template>
                    <template #notingTips>
                        <div>
                            啊哦，这里还没有任何东西呢，快去
                            <router-link :to="{name: 'centerDresserCelebrity'}">
                                上传
                            </router-link>
                            吧
                        </div>
                    </template>
                    <!-- 非网红 -->
                    <template #unbecomeTitle>
                        <div>
                            注：如果您拥有独特的影像风格，拥有其他社交媒体或平台的粉丝基础，您可以上传您的专属服务予我们审核，
                            <el-link type="primary">点击了解详情</el-link>
                        </div>
                    </template>
                    <template #unbecomeTips>
                        <div>
                            啊哦，你还不是网红化妆师，不能上传服务哦
                        </div>
                    </template>
                </center-member-tag>
            </el-tab-pane>
            <!-- <el-tab-pane 
                label="我的作品" 
                name="2"
                lazy>
                <center-member-works
                    :handle-submit="handleSave"
                    :handle-getworks="() => getMemberWorks(2)">
                </center-member-works>
            </el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    data() {
        let infoTabs = this.$route.query.id || '1';
        return {
            // 面包屑导航参数
            breadcrumb: [
                {
                    path: "/center/dresser",
                    path_name: "我是化妆师",
                },
                {
                    path: "/center/dresser/info",
                    path_name: "新增个人资料",
                },
            ],
            // 标签页
            infoTabs
        }
    },
    computed: mapState({
        user_id: state => state.user.user_id,
        basic_data: state => state.user.basic_data,
        work_list: state => state.user.work_list
    }),
    components: {
        // 面包屑导航组件
        breadCrumb: () => import('@/components/breadCrumb'),
        // 资料组件
        centerLensmanInfoData: () => import("@/components/centerLensmanInfoData"),
        // 作品组件
        centerMemberWorks: () => import("@/components/centerMemberWorks"),
        centerMemberTag: () => import("@/components/centerMemberTag")
    },
    methods: {
        ...mapMutations([
            "setWorksStyle",
            'setMemberGoodsList'
        ]),
        ...mapActions([
            "getDresserInfo",
            "handleDresserAvatar",
            "handleModifyDresser",
            "getWorksStyle",
            "handleSaveWorksStyle",
            "getMemberWorks",
            "getMemberGoodsList",
            "deleteMemberGoods"
        ]),
        // 查
        getData(tab) {
            let userID = this.user_id;
            // this.getBasicData();
            switch (tab) {
                case '2':
                   this.getMemberGoodsList({ userID, tagID: 4 });
                    break;
                default: 
                    this.getDresserInfo()
            }
        },
        handleClick(tab, event){ // 标签页切换
            // tab.name == 1? this.getDresserInfo(): this.getWorksStyle(2)
            let id = this.$route.query.id;
            if(tab.name != id){
                this.$router.push({
                    name: 'centerDresserInfo',
                    query: {
                        id: tab.name
                    }
                })
            }
        },
        handleSave({styleIDs, serviceArea, briefInfo, address, position}){
            this.handleSaveWorksStyle({
                type: 2,
                styleIDs,
                serviceArea,
                briefInfo,
                address,
                position
            }).then((res) => {
                // this.$message({
                //     showClose: true,
                //     message: res.msg,
                //     type: res.type? "success": "error"
                // })
                this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定'
                })
            })
        },
        handleDel(goodsID) {
            let userID = this.user_id,
                routeID = this.$route.query.id;
            this.$confirm('是否删除当前项？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                return this.deleteMemberGoods({ userID, goodsID })
            }).then(res => {
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.type? 'success': 'error'
                })
                res.type && this.getData(routeID);
            }).catch(err => {
                console.log(err)
            })
            
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            let tab = to.query.id;
            vm.getData(tab)
        })
    },
    beforeRouteUpdate(to, from, next) {
        let tab = to.query.id;
        this.getData(tab)
        next();
    },
    beforeRouteLeave (to, from, next) {
        this.setWorksStyle({});
        this.setMemberGoodsList([]);
        next();
    }
}
</script>

<style scoped>
.member-tag-tips-more{
    color: #909399;
}
</style>
